import styled from "styled-components";
import Navbar from "../components/Navbar";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
import { useLocation } from "react-router";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px", borderStyle: "solid" })}
`;
const Option = styled.option``;

const ProductList = () => {
  const location = useLocation();
  //console.log("location", location);
  const cat = location.pathname.split("/")[2];
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState("newest");

  const handleFilters = (e) => {
    const value = e.target.value;

    if (value === "Bütün boyutlar" || value === "Tüm renkler") {
      setFilters({});
    } else {
      setFilters({
        ...filters,
        [e.target.name]: value,
      });
    }
  };

  // console.log(sort);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Her boyutta ve farklı renklerde en iyi saç bandı çeşitleri uygun fiyatlarla"
        />
      </Helmet>

      <Container>
        <Navbar />
        <Title>{cat ? cat + " kategori" : "Tüm ürünler"}</Title>
        <FilterContainer>
          <Filter>
            <FilterText>Ürünleri Filtrele:</FilterText>
            <Select name="color" onChange={handleFilters}>
              <Option>Tüm renkler</Option>
              <Option
                style={{
                  color: "white",
                  background: "black",
                  fontWeight: "bold",
                }}
              >
                white
              </Option>
              <Option style={{ color: "black", fontWeight: "bold" }}>
                black
              </Option>
              <Option style={{ color: "red", fontWeight: "bold" }}>red</Option>
              <Option style={{ color: "blue", fontWeight: "bold" }}>
                blue
              </Option>
              <Option style={{ color: "yellow", fontWeight: "bold" }}>
                yellow
              </Option>
              <Option style={{ color: "green", fontWeight: "bold" }}>
                green
              </Option>
            </Select>
            <Select name="size" onChange={handleFilters}>
              {/* <Option disabled>Size</Option> */}
              <Option>Bütün boyutlar</Option>
              <Option>çocuk</Option>
              <Option>kadın</Option>
            </Select>
          </Filter>
          <Filter>
            <FilterText>Ürünleri Sırala:</FilterText>
            <Select onChange={(e) => setSort(e.target.value)}>
              <Option value="newest">En yeni</Option>
              <Option value="asc">Fiyat (artan)</Option>
              <Option value="desc">Fiyat (azalan)</Option>
            </Select>
          </Filter>
        </FilterContainer>
        <Products cat={cat} filters={filters} sort={sort} />
        <Newsletter />
        <Footer />
      </Container>
    </>
  );
};

export default ProductList;
